/* GameInfo.css */
.game-custom-card-container {
    display: flex;
    align-items: flex-start;
    max-width: 800px;
    margin: 0 auto; /* Center the card container */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    gap: 50px;
}
  
.game-custom-card {
    flex-shrink: 0; /* Prevent the card from shrinking */
    border-radius: 16px; /* This is for the card */
    overflow: hidden; /* Ensures the border radius is applied correctly */
    width: 40%; /* Adjust the width as needed */
}
  
.game-custom-card-img {
    width: 100%;
    height: 425px; /* Adjust the height as needed */
    object-fit: cover;
    pointer-events: none;
    transition: 0.5s;
    border-radius: 16px; /* This is for the image */
}
  
.game-details {
    margin-left: 20px;
    flex-grow: 1; /* Allow the details to grow and take remaining space */
}
  
.rating i {
    color: gold;
    margin-right: 5px;
}
  
.genres ul,
.game-modes ul {
    list-style-type: none;
    padding: 0;
}
  
.genres li,
.game-modes li {
    margin-bottom: 5px;
}
  
.platform-icons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-start; /* Align to the start */
}
  
.platform-icon {
    font-size: 24px;
    margin: 0 10px;
    color: #fff;
}
  
.game-info {
    gap: 20px;
    width: 8%;
}
  
.storyline h2 {
    padding-bottom: 10px;
    padding-top: 30px;
    font-family: EvilEmpire, sans-serif;
}
  
.storyline p {
    font-size: 1.25rem;
}
  
.screenshots-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
}
  
.screenshot-card {
    flex: 1;
    margin: 2px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    height: 300px;
    transition: flex 0.3s ease, background-color 0.3s ease;
}
  
.screenshot-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.screenshot-card:hover {
    flex: 2;
    height: var(--screenshot-height, 300px);
}
  
@media (max-width: 768px) {
    .game-custom-card-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center items */
    }
  
    .game-custom-card {
        width: 100%; /* Make the card full width */
        max-width: 60%; /* Ensure it doesn’t exceed 100% */
    }
  
    .game-details {
        margin-left: 0;
        margin-top: 20px;
        width: 100%; /* Make the details full width */
    }
  
    .platform-icons {
        justify-content: center; /* Center platform icons on small screens */
    }
}
  
@media (max-width: 517px) {
    .game-custom-card-container {
        gap: 20px; /* Reduce the gap for smaller screens */
    }

    .game-custom-card {
        width: 80%; /* Make the card smaller */
    }

    .game-details {
        margin-top: 10px;
    }

    .platform-icons {
        justify-content: center; /* Center platform icons on small screens */
    }

    .screenshot-card {
        height: 200px; /* Reduce screenshot card height */
    }

    .screenshot-card:hover {
        flex: 1; /* Prevent expansion on hover for smaller screens */
        height: var(--screenshot-height, 200px);
    }
}