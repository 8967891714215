.game-container {
    text-align: center;
    position: relative;
}

.game-name {
    margin-top: 10px;
    transition: margin-top 0.5s;
    font-size: 20px;
}

.custom-card {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    color: #fff;
    height: 400px; /* Ensure the card has a fixed height */
    display: flex;
    flex-direction: column; /* Ensure children elements stack vertically */
}

.custom-card-img {
    width: 100%;
    flex-grow: 1; /* Ensure the image grows to fill available space */
    object-fit: cover; /* Use cover to maintain aspect ratio and cover the entire card */
    pointer-events: none;
    transition: 0.5s;
}

.layer {
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75%;
    opacity: 0;
    transition: 0.3s;
}

.info {
    position: absolute;
    bottom: -50%;
    padding: 15px;
    opacity: 0;
    transition: 0.5s bottom, 1.75s opacity;
}

.info p {
    font-size: 14px;
    margin-top: 3px;
    color: #ccc;
    font-family: sans-serif;
}

.info button {
    background: #490CCC;
    border: none;
    padding: 8px 12px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 8px;
    color: white;
    margin-left: 8px;
}

.custom-card:hover,
.custom-card:hover .custom-card-img,
.custom-card:hover .layer {
    transform: scale(1.1);
}

.custom-card:hover .layer {
    opacity: 1;
}

.custom-card:hover .info {
    bottom: 0;
    opacity: 1;
}

.close-btn {
    background: #ff4d4d;
    border: none;
    padding: 8px 12px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 8px;
    color: white;
    display: block;
    margin: 20px auto;
}
@media (max-width: 768px) {
    .game-name {
        visibility: hidden;
    }
}