@import '../fonts.css';

/* General Styles */
body {
  background-color: black;
  color: white;
  font-family: 'EvilEmpire', sans-serif;
}

.homepage {
  background-color: black;
  color: white;
  min-height: 100vh;
  font-family: 'EvilEmpire', sans-serif;
}

/* Navbar Styles */
.navbar-custom {
  background-color: #2D3B41 !important;
  font-family: 'EvilEmpire', sans-serif;
  font-size: 1.1rem; /* Increase the font size here */
}

.navbar-brand-custom {
  color: #ffffff !important;
  font-size: 1.5rem; /* Increase the font size here */
}

.nav-link-custom {
  color: #ffffff !important;
  font-size: 1.1rem; /* Increase the font size here */
}

/* Search Form Styles */
.search-form-custom {
  display: flex;
}

.search-input-custom {
  margin-right: 0.5rem;
}

/* Welcome Section Styles */
.welcome-section {
  margin-top: 5rem;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-title {
  font-size: 4.5rem;
  font-weight: bold;
  text-align: center;
  font-family: EvilEmpire, sans-serif;
}

.welcome-text {
  font-size: 1.25rem;
}

/* Loading Styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Game Card Styles */
.game-container {
  text-align: center;
  position: relative;
}

.game-name {
  margin-top: 10px;
  transition: margin-top 0.5s;
  font-size: 20px;
}

.custom-card {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  color: black;
}

.custom-card:hover {
  transform: scale(1.05);
}

.custom-card-img {
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  transition: 0.5s;
}

.layer {
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 75%;
  opacity: 0;
  transition: 0.3s;
}

.info {
  position: absolute;
  bottom: -50%;
  padding: 15px;
  opacity: 0;
  transition: 0.5s bottom, 1.75s opacity;
}

.info p {
  font-size: 14px;
  margin-top: 3px;
  color: #ccc;
  font-family: sans-serif;
}

.info button {
  background: #490CCC;
  border: none;
  padding: 8px 12px;
  font-weight: bold;
  border-radius: 8px;
  margin-top: 8px;
  color: white;
  margin-left: 8px;
}

.custom-card:hover,
.custom-card:hover .custom-card-img,
.custom-card:hover .layer {
  transform: scale(1.1);
}

.custom-card:hover .layer {
  opacity: 1;
}

.custom-card:hover .info {
  bottom: 0;
  opacity: 1;
}
.platform-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.platform-icons i {
  font-size: 24px;
  color: white;
}
