.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.pagination {
    display: flex;
    justify-content: center; /* Center the buttons */
    margin-top: 20px;
}

.pagination .icon {
    position: relative;
    background: #490CCC;
    border-radius: 50%;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.pagination .icon:hover {
    background: #370999;
}

.pagination .icon svg {
    fill: white;
}

.wrapper span:hover,
.wrapper svg:hover,
.wrapper .black:hover,
.wrapper .black:hover .tooltip,
.wrapper .black:hover .tooltip::before {
    background: #222;
    color: white;
    fill: white;
}

/* Add keyframe animations */
@keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 500px; /* Adjust this value as needed */
      opacity: 1;
    }
}

@keyframes slideUp {
    from {
      max-height: 500px; /* Same value as above */
      opacity: 1;
    }
    to {
      max-height: 0;
      opacity: 0;
    }
}

/* Add transition for smooth opening and closing */
.expanding-box {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 1.5s ease-in-out, opacity 1s ease-in-out;
    background: white; /* Make sure the box has a background color */
    color: black; /* Adjust text color as needed */
    margin-top: 30px; /* Add some space above the expanding box */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
    padding: 10px; /* Add some padding inside the box */
    position: relative; /* Ensure it pushes other content down */
    width: calc(100vw - 20px); /* Span full screen width minus some padding */
    left: 50%; /* Align center */
    transform: translateX(-50%); /* Center horizontally */
    z-index: 1000; /* Ensure it appears above other elements */
}

.expanding-box.open {
    max-height: 500px; /* Adjust this value as needed */
    opacity: 1;
    animation: slideDown 0.5s forwards;
}

.hide-content {
    animation: slideUp 0.5s forwards;
}

.game-container {
    text-align: center;
    position: relative;
}

.game-name {
    margin-top: 10px;
    transition: margin-top 0.5s;
    font-size: 20px;
}

.custom-card {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    transition: 0.5s;
    cursor: pointer;
    color: #fff;
    height: 400px;
}

.custom-card:hover {
    transform: scale(1.05);
}

.custom-card-img {
    width: 100%;
    height: 100%; /* Adjust to fill the card */
    object-fit: fill;
    pointer-events: none;
    transition: 0.5s;
}

.layer {
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75%;
    opacity: 0;
    transition: 0.3s;
}

.info {
    position: absolute;
    bottom: -50%;
    padding: 15px;
    opacity: 0;
    transition: 0.5s bottom, 1.75s opacity;
}

.info p {
    font-size: 14px;
    margin-top: 3px;
    color: #ccc;
    font-family: sans-serif;
}

.info button {
    background: #490CCC;
    border: none;
    padding: 8px 12px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 8px;
    color: white;
    margin-left: 8px;
}

.custom-card:hover,
.custom-card:hover .custom-card-img,
.custom-card:hover .layer {
    transform: scale(1.1);
}

.custom-card:hover .layer {
    opacity: 1;
}

.custom-card:hover .info {
    bottom: 0;
    opacity: 1;
}

.close-btn {
    background: #ff4d4d;
    border: none;
    padding: 8px 12px;
    font-weight: bold;
    border-radius: 8px;
    margin-top: 8px;
    color: white;
    display: block;
    margin: 20px auto;
}